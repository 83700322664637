import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { shallow } from '@jwp/ott-common/src/utils/compare';
import type { PlaylistItem } from '@jwp/ott-common/types/playlist';
import { useConfigStore } from '@jwp/ott-common/src/stores/ConfigStore';
import { useAccountStore } from '@jwp/ott-common/src/stores/AccountStore';
import { createVideoMetadata } from '@jwp/ott-common/src/utils/metadata';
import { mediaURL } from '@jwp/ott-common/src/utils/urlFormatting';
import { generateMovieJSONLD } from '@jwp/ott-common/src/utils/structuredData';
import useMedia from '@jwp/ott-hooks-react/src/useMedia';
import usePlaylist from '@jwp/ott-hooks-react/src/usePlaylist';
import useEntitlement from '@jwp/ott-hooks-react/src/useEntitlement';
import useBreakpoint, { Breakpoint } from '@jwp/ott-ui-react/src/hooks/useBreakpoint';
import PlayTrailer from '@jwp/ott-theme/assets/icons/play_trailer.svg?react';
import useQueryParam from '@jwp/ott-ui-react/src/hooks/useQueryParam';
import env from '@jwp/ott-common/src/env';
import type { ScreenComponent } from '@jwp/ott-ui-react/types/screens';
import StartWatchingButton from '@jwp/ott-ui-react/src/containers/StartWatchingButton/StartWatchingButton';
import Cinema from '@jwp/ott-ui-react/src/containers/Cinema/Cinema';
import TrailerModal from '@jwp/ott-ui-react/src/containers/TrailerModal/TrailerModal';
import ShareButton from '@jwp/ott-ui-react/src/components/ShareButton/ShareButton';
import FavoriteButton from '@jwp/ott-ui-react/src/containers/FavoriteButton/FavoriteButton';
import Button from '@jwp/ott-ui-react/src/components/Button/Button';
import Icon from '@jwp/ott-ui-react/src/components/Icon/Icon';
import VideoMetaData from '@jwp/ott-ui-react/src/components/VideoMetaData/VideoMetaData';
import CardGrid from '@jwp/ott-ui-react/src/components/CardGrid/CardGrid';
import Hero from '@jwp/ott-ui-react/src/components/Hero/Hero';
import Tabs from '@jwp/ott-ui-react/src/components/Tabs/Tabs';
import Tab from '@jwp/ott-ui-react/src/components/Tab/Tab';

import VideoLayout from '../../../../components/VideoLayout/VideoLayout';
import InlinePlayer from '../../../../containers/InlinePlayer/InlinePlayer';

import styles from './MediaMovieVideodock.module.scss';
import VideoFormats from './components/VideoFormats/VideoFormats';
import VideoDefinitionList from './components/VideoDefintionsList/VideoDefinitionList';
import VideoAboutSection from './components/VideoAboutSection/VideoAboutSection';

const countListValues = (value: string) => (!value ? 0 : value.split(',').length);

const MediaMovieVideodock: ScreenComponent<PlaylistItem> = ({ data, isLoading }) => {
  const { t } = useTranslation('video');

  const [playTrailer, setPlayTrailer] = useState<boolean>(false);
  const breakpoint = useBreakpoint();

  // Routing
  const navigate = useNavigate();

  const params = useParams();
  const id = params.id || '';
  const play = useQueryParam('play') === '1';
  const feedId = useQueryParam('r');

  // Config
  const { config, accessModel } = useConfigStore(({ config, accessModel }) => ({ config, accessModel }), shallow);
  const { siteName, features } = config;

  const isFavoritesEnabled: boolean = Boolean(features?.favoritesList);

  const inlinePlayer = useQueryParam('inlinePlayer');

  // Media
  const { isLoading: isTrailerLoading, data: trailerItem } = useMedia(data?.trailerId || '');
  const usedPlaylistId = inlinePlayer ? feedId : features?.recommendationsPlaylist || '';
  const { isLoading: isPlaylistLoading, data: playlist } = usePlaylist(usedPlaylistId || '', { related_media_id: inlinePlayer ? undefined : id });

  // User, entitlement
  const { user, subscription } = useAccountStore(({ user, subscription }) => ({ user, subscription }), shallow);
  const { isEntitled } = useEntitlement(data);

  const additional = useMemo(() => {
    return inlinePlayer
      ? {
          r: feedId,
          inlinePlayer: '1',
          play: 1,
        }
      : {};
  }, [feedId, inlinePlayer]);

  // Handlers
  const goBack = () => data && navigate(mediaURL({ id: data.mediaid, title: data.title, playlistId: feedId, play: false }));
  const getUrl = (item: PlaylistItem) => mediaURL({ id: item.mediaid, title: item.title, playlistId: features?.recommendationsPlaylist, additional });

  const handleComplete = useCallback(() => {
    if (!id || !playlist) return;

    const index = playlist.playlist.findIndex(({ mediaid }) => mediaid === id);
    const nextItem = playlist.playlist[index + 1];

    return (
      nextItem && navigate(mediaURL({ id: nextItem.mediaid, title: nextItem.title, playlistId: features?.recommendationsPlaylist, play: true, ...additional }))
    );
  }, [id, playlist, navigate, features?.recommendationsPlaylist, additional]);

  useEffect(() => {
    (document.scrollingElement || document.body).scroll({ top: 0 });
    (document.querySelector('#video-details button') as HTMLElement)?.focus();
  }, [id]);

  // UI
  const pageTitle = `${data.title} - ${siteName}`;
  const canonicalUrl = data ? `${env.APP_PUBLIC_URL}${mediaURL({ id: data.mediaid, title: data.title })}` : window.location.href;

  const isLoggedIn = !!user;
  const hasSubscription = !!subscription;
  const hasTrailer = !!trailerItem || isTrailerLoading;
  const hasVideoFormats = ['subtitleFormat', 'videoFormat', 'audioFormat'].some((property) => data[property]);
  const [selectedTab, setSelectedTab] = useState(0);

  const startWatchingButton = (
    <StartWatchingButton
      key={id} // necessary to fix autofocus on TalkBack
      item={data}
      playUrl={mediaURL({ id: data.mediaid, title: data.title, playlistId: feedId, play: true })}
    />
  );

  if (inlinePlayer) {
    return (
      <VideoLayout
        item={data}
        inlineLayout={true}
        isLoading={isLoading || isPlaylistLoading}
        accessModel={accessModel}
        isLoggedIn={isLoggedIn}
        hasSubscription={hasSubscription}
        title={data.title}
        description={data.description}
        image={data.backgroundImage}
        // primaryMetadata={primaryMetadata}
        // shareButton={shareButton}
        // favoriteButton={favoriteButton}
        // trailerButton={trailerButton}
        // startWatchingButton={startWatchingButton}
        playlist={playlist}
        relatedTitle={playlist?.title}
        getURL={getUrl}
        activeLabel={t('current_video')}
        player={
          <InlinePlayer
            isLoggedIn={isLoggedIn}
            item={data}
            onComplete={handleComplete}
            feedId={feedId ?? undefined}
            startWatchingButton={startWatchingButton}
            isEntitled={isEntitled}
            hasMediaOffers={false}
            autostart={play || undefined}
          />
        }
      />
    );
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="canonical" href={canonicalUrl} />
        <meta name="description" content={data.description} />
        <meta property="og:description" content={data.description} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:type" content="video.other" />
        {data.image && <meta property="og:image" content={data.image?.replace(/^https:/, 'http:')} />}
        {data.image && <meta property="og:image:secure_url" content={data.image?.replace(/^http:/, 'https:')} />}
        <meta property="og:image:width" content={data.image ? '720' : ''} />
        <meta property="og:image:height" content={data.image ? '406' : ''} />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={data.description} />
        <meta name="twitter:image" content={data.image} />
        <meta property="og:video" content={canonicalUrl.replace(/^https:/, 'http:')} />
        <meta property="og:video:secure_url" content={canonicalUrl.replace(/^http:/, 'https:')} />
        <meta property="og:video:type" content="text/html" />
        <meta property="og:video:width" content="1280" />
        <meta property="og:video:height" content="720" />
        {data.tags?.split(',').map((tag) => (
          <meta property="og:video:tag" content={tag} key={tag} />
        ))}
        {data ? <script type="application/ld+json">{generateMovieJSONLD(data, env.APP_PUBLIC_URL)}</script> : null}
      </Helmet>
      <div>
        <header id="video-details">
          <Hero image={data.backgroundImage} infoClassName={breakpoint < Breakpoint.md ? styles.infoSmallScreenReorder : undefined}>
            <h1 className={styles.title}>{data.title}</h1>
            <div className={styles.metaContainer}>
              {/* TODO implement rating icons. See: OTT-3206 */}
              {hasVideoFormats && <VideoFormats item={data} />}
              <VideoMetaData
                attributes={[
                  ...createVideoMetadata(data, {
                    hoursAbbreviation: t('common:abbreviation.hours'),
                    minutesAbbreviation: t('common:abbreviation.minutes'),
                  }),
                  ...(data?.contentType ? [data.contentType] : []),
                ]}
              />
            </div>
            {!!data.synopsis && <p className={styles.synopsis}>{data.synopsis as string}</p>}
            <VideoDefinitionList
              className={styles.definitionListHorizontal}
              splitByComma={false}
              listProperties={[
                { label: t('video:metadata.actors', { count: countListValues(data?.actors as string) }), value: data?.actors as string },
                { label: t('video:metadata.directors', { count: countListValues(data?.directors as string) }), value: data?.directors as string },
              ]}
            />
            <div className={styles.buttonBar}>
              <StartWatchingButton
                key={id} // necessary to fix autofocus on TalkBack
                item={data}
                playUrl={mediaURL({ id: data.mediaid, title: data.title, playlistId: feedId, play: true })}
              />
              {breakpoint < Breakpoint.md ? (
                <>
                  {hasTrailer && (
                    <Button
                      label={t('video:trailer')}
                      aria-label={t('video:watch_trailer')}
                      startIcon={<Icon icon={PlayTrailer} />}
                      onClick={() => setPlayTrailer(true)}
                      active={playTrailer}
                      disabled={!trailerItem}
                      className={styles.rectangleButton}
                    />
                  )}
                  {isFavoritesEnabled && <FavoriteButton item={data} className={styles.rectangleButton} />}
                  <ShareButton title={data.title} description={data.description} url={canonicalUrl} className={styles.rectangleButton} />
                </>
              ) : (
                <>
                  {hasTrailer && (
                    <Button
                      label={t('video:trailer')}
                      aria-label={t('video:watch_trailer')}
                      startIcon={<Icon icon={PlayTrailer} />}
                      onClick={() => setPlayTrailer(true)}
                      active={playTrailer}
                      disabled={!trailerItem}
                    />
                  )}
                  {isFavoritesEnabled && <FavoriteButton item={data} className={styles.roundButton} />}
                  <ShareButton title={data.title} description={data.description} url={canonicalUrl} className={styles.roundButton} />
                </>
              )}
            </div>
          </Hero>
        </header>
        <div className={styles.tabsContainer}>
          <Tabs onTabSelect={setSelectedTab} selectedTab={selectedTab}>
            {playlist ? (
              <Tab title={playlist?.title || '\u00A0'}>
                <CardGrid
                  playlist={playlist}
                  isLoading={isLoading || isPlaylistLoading}
                  //watchHistory={watchHistory}
                  accessModel={accessModel}
                  isLoggedIn={isLoggedIn}
                  currentCardItem={data}
                  currentCardLabel={t('current_video')}
                  hasSubscription={hasSubscription}
                  getUrl={getUrl}
                />
              </Tab>
            ) : null}
            <Tab title={t('video:about_tab')}>
              <VideoAboutSection
                description={data.description}
                metadata={
                  <div className={styles.aboutDefinitionList}>
                    <VideoDefinitionList
                      className={styles.definitionListVertical}
                      listProperties={[
                        { label: t('video:metadata.directors', { count: countListValues(data?.directors as string) }), value: data?.directors as string },
                        { label: t('video:metadata.cast'), value: data?.cast as string },
                        { label: t('video:metadata.rating'), value: data?.rating as string },
                        ...(hasVideoFormats ? [{ label: t('video:metadata.formats'), value: <VideoFormats item={data} /> }] : []),
                      ]}
                    />
                    <VideoDefinitionList
                      className={styles.definitionListVertical}
                      listProperties={[
                        { label: t('video:metadata.genres', { count: countListValues(data?.genre as string) }), value: data?.genre as string },
                        { label: t('video:metadata.audio_languages'), value: data?.audioLanguages as string },
                        { label: t('video:metadata.subtitle_languages'), value: data?.subtitleLanguages as string },
                      ]}
                    />
                  </div>
                }
              />
            </Tab>
          </Tabs>
        </div>
        <Cinema
          open={play && isEntitled}
          onClose={goBack}
          item={data}
          title={data.title}
          primaryMetadata={
            <VideoMetaData
              attributes={createVideoMetadata(data, {
                hoursAbbreviation: t('common:abbreviation.hours'),
                minutesAbbreviation: t('common:abbreviation.minutes'),
              })}
            />
          }
          onComplete={handleComplete}
          feedId={feedId ?? undefined}
          onNext={handleComplete}
        />
      </div>
      <TrailerModal item={trailerItem} title={`${data.title} - Trailer`} open={playTrailer} onClose={() => setPlayTrailer(false)} />
    </React.Fragment>
  );
};

export default MediaMovieVideodock;
